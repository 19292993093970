<template>
  <section class="splash-image-configurator scroll-content">
    <h3 class="md-display-1">{{ $translate("splash_image_configurator.title") }}:</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("splash_image_configurator.message") }}</div>
    </section_description_preview>

    <div class="splash-image-configurator__images">
      <div class="md-layout md-gutter">
        <img
          v-for="color in splash_colors"
          :key="`splash-colors-${color}`"
          :src="`/static/backgrounds/color-splash-round-${color}.png`"
          :class="{ 'splash-image-configurator__selected': rest_config.splash_image_color === color}"
          @click="can_user_manage_configuration && select_splash_image(color)"
          class="md-layout-item md-size-20 md-medium-size-20 md-small-size-50 md-xsmall-size-50"
        >
      </div>
      <md-button
        v-if="can_user_manage_configuration && rest_config.splash_image_color"
        class="md-raised md-accent"
        @click="select_splash_image(null)"
      >{{ $translate("splash_image_configurator.disable_splash") }}</md-button>
    </div>

    <div class="splash-image-configurator__preview">
      <h3>{{ $translate("live_preview") }}:</h3>
      <img
        v-if="rest_config.splash_image_color"
        class="splash-image-configurator__background"
        :src="`/static/backgrounds/color-splash-round-${rest_config.splash_image_color}.png`"
      >
      <img
        class="splash-image-configurator__product-img"
        :class="{ 'splash-image-configurator__product-img--no-background': !rest_config.splash_image_color }"
        :src="get_transparent_image.src.mobile"
      >
    </div>

    <md-button
      v-if="can_user_manage_configuration"
      class="md-raised md-primary splash-image-configurator__centered"
      @click="open_entity_selection_modal = true"
    >{{ $translate("splash_image_configurator.open_product_selector") }}</md-button>

    <entity_selection_modal
      :modal_title="$translate('splash_image_configurator.select_product')"
      :open_modal="open_entity_selection_modal"
      :enable_collection_selection="false"
      @entity_selected="select_preview_product"
      @toggle_modal="val => open_entity_selection_modal = val"
    />

    <md-snackbar md-position="center" :md-duration="10000" :md-active="is_transparent_image" md-persistent>
      <span>{{ $translate('splash_image_configurator.transparent_image_message') }}</span>
      <md-button class="md-primary" @click="is_transparent_image = false">{{ $translate('cancel') }}</md-button>
    </md-snackbar>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { ADMIN, CONFIGURATION_STORE, PRODUCTS_STORE } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import { UPDATE_PROJECT_REST_CONFIG } from "../../../stores/Admin/configuration/constants"
import { transparent } from "../../../../../data/other_constants"
import { default_image_src } from "../../../../Shared/constants/other"
import entity_selection_modal from "../entity_selection_modal"
import get_image_src from "../../../../Shared/methods/get_image_src"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    entity_selection_modal,
    section_description_preview
  },
  data() {
    return {
      open_entity_selection_modal: false,
      selected_product_id: undefined,
      splash_colors: ["red", "blue", "green", "orange", "pink", "yellow", "jungle"],
      is_transparent_image: false
    }
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["rest_config"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_products",
      "shopify_products",
    ]),
    products() {
      return [...(this.imported_products || []), ...(this.shopify_products || [])]
    },
    get_correct_product() {
      return this.products.find(({ id }) => this.selected_product_id === id)
    },
    get_transparent_image() {
      if (this.selected_product_id) {
        return this.get_correct_product?.images?.find(image =>
          get_image_src(image).includes(transparent)
        ) || this.get_correct_product.images[0]
      }

      const products_with_images = this.products.filter(({ images }) => images?.length) || []
      const products_with_transparent = this.products.filter(({ images }) => images?.some(image => get_image_src(image)?.includes(transparent)))

      if (!products_with_images.length) return { src: default_image_src }

      return get_image_src(
        products_with_transparent.length ?
          products_with_transparent[0].images.find(image => get_image_src(image)?.includes(transparent)) :
          this.products.filter(({ images }) => images?.length)[0].images[0]
        || {}
      )
    }
  },
  watch: {
    get_transparent_image() {
      this.is_transparent_image = !this.get_correct_product.images.some(image => get_image_src(image).includes(transparent))
    }
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_project_rest_config: UPDATE_PROJECT_REST_CONFIG
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED
    }),
    select_splash_image(image_type) {
      this.update_project_rest_config(["splash_image_color", image_type])
      this.configuration_data_updated({ types_updated: ["rest_config"] })
    },
    select_preview_product(id) {
      this.selected_product_id = id
      this.open_entity_selection_modal = false
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .splash-image-configurator {

    &__images {
      text-align: center;

      img {
        cursor: pointer;
      }

      button {
        display: block;
        margin: $double-default-size auto;
      }
    }

    &__centered {
      display: block;
      margin: $default-size auto;
    }

    &__selected {
      border: 1px solid $blue;
      border-radius: $border-radius;
    }

    &__preview {
      position: relative;
    }

    &__background {
      display: block;
      width: 80%;
      max-width: 500px;
      margin: auto;
    }

    &__product-img {
      position: absolute;
      top: calc(50% + 30px);
      left: 50%;
      max-width: 200px;
      max-height: 200px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      filter: drop-shadow(0 0 15px $pure-black);
      
      &--no-background {
        position: relative;
        display: block;
        top: auto;
        left: auto;
        max-width: 400px;
        max-height: 400px;
        margin: $default-size auto;
        transform: none;
        filter: drop-shadow(0 0 30px rgba($pure-black, 0.5));
      }
    }
  }
</style>
