<template>
  <md-card class="edit-page-type-translations__card">
    <md-card-header>
      <div class="md-title">
        <a v-if="correct_entity_title" :href="correct_entity_url" target="_blank">
          {{ correct_entity_title }}
          <md-icon class="md-primary">open_in_new</md-icon>
        </a>
        <span v-else>{{ $translate("unknown") }}</span>
      </div>

      <div class="edit-page-type-translations__actions">
        <md-button
          v-if="correct_entity_title"
          class="md-icon-button md-raised"
          @click="copy_to_clipboard"
        >
          <md-icon>content_copy</md-icon>
        </md-button>
        <md-button
          v-if="can_user_manage_configuration"
          class="md-fab md-mini md-raised md-accent"
          @click="() => delete_page(page_id, true)"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="top">{{ $translate("delete") }}</md-tooltip>
        </md-button>
      </div>
    </md-card-header>

    <md-card-expand>
      <md-card-actions md-alignment="space-between">
        <language_switcher
          v-if="can_user_manage_configuration"
          :selected_language="selected_language"
          @change-language="language => selected_language = language"
          :allow_only_enabled="true"
          :show_all_languages="false"
          :show_title="false"
          :sizes="{ width: '30px', heigth: '20px' }"
        />

        <md-card-expand-trigger>
          <md-button class="md-icon-button">
            <md-icon>keyboard_arrow_down</md-icon>
          </md-button>
        </md-card-expand-trigger>
      </md-card-actions>

      <md-card-expand-content>
        <md-card-content>
          <hr>
          <div
            v-for="([sub_key, value]) in Object.entries(correct_language_translations)"
            :key="sub_key"
            class="edit-page-type-translations__translation"
          >
            <span>{{ replace_underscores_for_spaces(sub_key) | capitalize }}</span>
            <md-field>
              <md-input
                :disabled="!can_user_manage_configuration"
                :value="value || ''"
                @change="event => update_page_type_translation(page_id, sub_key, event.target.value, selected_language)"
                :placeholder="replace_underscores_for_spaces(sub_key)"
              />
            </md-field>
          </div>
        </md-card-content>
      </md-card-expand-content>
    </md-card-expand>
  </md-card>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONFIGURATION_STORE, ADMIN, PRODUCTS_STORE, shopify, imported } from "../../../constants/others_constants"
import { UPDATE_PAGE_TYPE_TRANSLATION, DELETE_PAGE_TYPE_TRANSLATION } from "../../../stores/Admin/configuration/constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import replace_underscores_for_spaces from "../../../../Shared/methods/replace_underscores_for_spaces"
import { default_language, collection, product } from "../../../../Shared/constants/other"
import { page_types } from "../../../../../data/other_constants"
import language_switcher from "../language_switcher"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import default_page_type_translations_cz from "../../../constants/empty_objects/default_page_type_translations_cz"
import default_page_type_translations from "../../../constants/empty_objects/default_page_type_translations"
import get_correct_domain from "../../../methods/get_correct_domain"

export default {
  components: {
    language_switcher
  },
  props: {
    page_id: {
      required: true,
      type: String
    },
    page_translations: {
      required: true,
      type: Object
    },
    can_user_manage_configuration: {
      required: true,
      type: Boolean
    },
    is_single_product_page: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      selected_language: default_language
    }
  },
  mounted() {
    this.select_initial_language()
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      page_types.single_product_homepages,
      page_types.couple_products_homepages,
      "translations",
      "shopify_config",
      "project_config",
      "project_name_id"
    ]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    get_all_products() {
      return this[`${this.products_type}_products`] || []
    },
    get_all_collections() {
      return this[`${this.products_type}_collections`] || []
    },
    correct_page_type() {
      return this.is_single_product_page ?
        page_types.single_product_homepages :
        page_types.couple_products_homepages
    },
    correct_language_translations() {
      return this.page_translations?.[this.selected_language] || 
        (
          this.selected_language === "cz" ?
            default_page_type_translations_cz :
            default_page_type_translations
        )[this.correct_page_type]
    },
    correct_entity() {
      return this.is_single_product_page ?
        this.get_all_products.find(product => product.buffer_id === this.page_id || product.id === this.page_id) :
        this.get_all_collections.find(collection => collection.id === this.page_id)
    },
    correct_entity_title() {
      return get_correct_translation_value(
        (
          this.is_single_product_page ? this.get_all_products : this.get_all_collections
        ).find(({ id, handle }) => this.page_id === id || this.page_id === handle ),
        "title",
        [this.selected_language]
      )
    },
    correct_entity_url() {
      const entity = `/?${
          this.is_single_product_page ? product : collection
        }=${
          this.correct_entity?.handle || this.correct_entity?.buffer_id || this.correct_entity?.id
        }`

      return get_correct_domain(this.project_config.project_id, this.project_name_id, this.project_config.domain) + entity
    },
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_page_type: UPDATE_PAGE_TYPE_TRANSLATION,
      delete_page_type: DELETE_PAGE_TYPE_TRANSLATION,
    }),
    ...mapMutations(ADMIN, {
      configuration_data_update: CONFIGURATION_DATA_UPDATED,
    }),
    replace_underscores_for_spaces,
    select_initial_language() {
      const language = this.translations[default_language] ? default_language : Object.keys(this.translations)[0]

      this.selected_language = language
    },
    update_page_type_translation(handle, page_id, value, language) {
      this.update_page_type([this.correct_page_type, handle, page_id, value, language])
      this.configuration_data_update({ types_updated: [this.correct_page_type] })
    },
    delete_page(page_id) {
      this.delete_page_type([
        this.correct_page_type,
        page_id
      ])
      this.configuration_data_update({ types_updated: [this.correct_page_type] })
    },
    copy_to_clipboard() {
      navigator.clipboard.writeText(this.correct_entity_url);
    },
  }
}
</script>
